import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout, SEO, CJumbotron, CBreadCrumb, LWrap, NavAbout, CGridCard, AssetImage
} from "../../../components/_index"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  console.log(data.markdownRemark)
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CJumbotron
        data={{
          title: {
            ja: 'ロイヤルパークホテルズ ギフト券'
          },
          img: {
            src: '/assets/images/common/kv.png'
          },
          imgSp: {
            src: '/assets/images/common/kv__sp.png'
          }
        }}
      />
      <CBreadCrumb data={{
        parent: [
          {
            label: 'ロイヤルパークホテルズについて',
            path: '/about/'
          },
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <div className="l_sect02">
        <LWrap>
          <p className="c_sectLead">ロイヤルパークホテルズ各ホテルにて、ご宿泊・お食事・お買い物などにご利用いただけるギフト券です。<br />1,000円、5,000円、10,000円の3種類をご用意しております。有効期限はございません。</p>
          <CGridCard exClass="u_mbLarge" gutter="ExSmall" col={3} data={[
            {
              content: <>
                <AssetImage src="/assets/images/about/gift_card/img_giftcard.png" alt="" />
              </>
            },
            {
              content: <>
                <AssetImage src="/assets/images/about/gift_card/img_giftcard02.png" alt="" />
              </>
            },
            {
              content: <>
                <AssetImage src="/assets/images/about/gift_card/img_giftcard03.png" alt="" />
              </>
            },
          ]} />
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">ご購入いただけるホテル </h2>
            <p>仙台ロイヤルパークホテル<br />ロイヤルパークホテル（東京・日本橋）<br />横浜ロイヤルパークホテル<br />ザ ロイヤルパークホテル アイコニック 東京汐留</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">ご利用に関する注意事項 </h2>
            <p>本券はロイヤルパークホテルズ各ホテルにてご宿泊、ご飲食などにご利用いただけます。<br />各ホテルにおいて、一部ご利用いただけない施設もございます。ご利用の際は、各ホテルにご確認ください。<br />本券の払い戻し、現金化および他額面券との交換はいたしかねます。<br />本券の盗難、紛失、滅失などにつきましては当方は責任を負いかねます。</p>
          </section>
        </LWrap>
      </div>
      <NavAbout />
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`